import newcarLogo from '@/newcar/ui/common/img/logo/logo_newcar.svg';
import bnrLp from '@/newcar/ui/top/img/topbanner/lp-bnr.png';
import bnrLpAvif from '@/newcar/ui/top/img/topbanner/lp-bnr.avif';
import React, { FC, useState } from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router'; // useLocation フックをインポート
import NEWCAR_DEFINE from '@/newcar/util/define';

import HeaderMegaMenu from '@/newcar/ui/common/molecule/HeaderMegaMenu';

import * as styles from './Header.module.styl';

interface State {
  isMenuActive: boolean;
}

type HeaderProps = {
  h1Text?: string;
};

const Header: FC<HeaderProps> = ({ children, h1Text }) => {
  const [state, setState] = useState<State>({
    isMenuActive: false,
  });

  const location = useLocation(); // 現在のページのパスを取得
  const isHomePage = location.pathname === '/'; // トップページかどうかを判定

  const toggleMenu = (): void => {
    const { isMenuActive } = state;
    setState({
      isMenuActive: !isMenuActive,
    });
  };

  return (
    <header className={`${styles.header_nav} is-full-width no_fixed is-zindex-menu"`}>
      <nav
        className={`${styles.container} container navbar is-zindex-menu`}
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <div className="navbar-item">
            <Link to="/" className="is-flex has_vertical_centered">
              <div className="">
                <img
                  className="is-full-width"
                  src={newcarLogo}
                  alt={h1Text || 'おトクにマイカー 定額カルモくん'}
                />
              </div>
            </Link>
          </div>
          <div
            onClick={toggleMenu}
            role="button"
            className={`navbar-burger burger navbtn ${
              state.isMenuActive ? 'is-active' : ''
            }`}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarManus"
            tabIndex={0}
            id="nvbtn"
            data-gtm="gtm-newcar-navbar"
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </div>
        </div>
        <div
          id="navbarManus"
          className={`navbar-menu ${state.isMenuActive ? 'is-active' : ''}`}
        >
          <div className="navbar-start" />
          <div className="navbar-end">
            {/* PCメニュー */}
            <HeaderMegaMenu />

            {/* SPメニュー */}
            <div className="navbar-item is-hoverable has-dropdown has-text-black-ter">
              <div className={`${styles.navbar_dropdown} navbar-dropdown`}>
                <Link className={`${styles.navbar_icon} ${styles.navbar_item} navbar-item`} to={NEWCAR_DEFINE.PATH.FIRST_GUIDE} data-gtm="gtm-newcar-navbar-first-guide">
                  はじめての方へ
                </Link>
                <Link className={`${styles.navbar_icon} ${styles.navbar_item} navbar-item`} to={NEWCAR_DEFINE.PATH.ABOUT_CARMO} data-gtm="gtm-newcar-navbar-about">
                  定額カルモくんの特徴
                </Link>
                <Link className={`${styles.navbar_icon} ${styles.navbar_item} navbar-item`} to={NEWCAR_DEFINE.PATH.FEE} data-gtm="gtm-newcar-navbar-fee">
                  料金について
                </Link>
                <Link className={`${styles.navbar_icon} ${styles.navbar_item} navbar-item`} to={NEWCAR_DEFINE.PATH.NEWCAR_LINEUP} data-gtm="gtm-newcar-navbar-newcar-search">
                  新車を探す
                </Link>
                <Link className={`${styles.navbar_icon} ${styles.navbar_item} navbar-item`} to={NEWCAR_DEFINE.PATH.USEDCAR_TOP} data-gtm="gtm-newcar-navbar-usedcar-search">
                  中古車を探す
                </Link>

                {/* サービス内容 */}
                <details open className={`${styles.accordion_nav}`}>
                  <summary className={`${styles.navbar_button}`}>オプション・保険</summary>
                  <div className={`${styles.navbar_item_container}`}>
                    <Link className={`${styles.navbar_icon} ${styles.navbar_item_inner}`} to={NEWCAR_DEFINE.PATH.MORAERU} data-gtm="gtm-newcar-navbar-moraeru">
                      もらえるオプション
                    </Link>
                    <Link className={`${styles.navbar_icon} ${styles.navbar_item_inner}`} to={NEWCAR_DEFINE.PATH.MAINTENANCE} data-gtm="gtm-newcar-navbar-maintenance">
                      メンテナンスプラン
                    </Link>
                    <Link
                      className={`${styles.navbar_icon} ${styles.navbar_item_inner}`}
                      to={NEWCAR_DEFINE.PATH.CAR_INSURANCE}
                      data-gtm="gtm-newcar-navbar-carinsurance"
                    >
                      自動車保険
                    </Link>
                  </div>
                </details>
                <details open className={`${styles.accordion_nav}`}>
                  <summary className={`${styles.navbar_button}`}>運営会社・スタッフ</summary>
                  <div className={`${styles.navbar_item_container}`}>
                    <a
                      className={`${styles.navbar_icon} ${styles.navbar_item_inner} navbar-item`}
                      href={NEWCAR_DEFINE.PATH.COMPANY_ABOUT}
                      data-gtm="gtm-newcar-navbar-docs-company"
                      target="_blank"
                      rel="noreferrer"
                    >
                      会社概要
                    </a>
                    <Link className={`${styles.navbar_icon} ${styles.navbar_item_inner}`} to={NEWCAR_DEFINE.PATH.STAFF} data-gtm="gtm-newcar-navbar-staff">
                      スタッフ紹介
                    </Link>
                    <Link
                      className={`${styles.navbar_icon} ${styles.navbar_item_inner} navbar-item`}
                      to={NEWCAR_DEFINE.PATH.SOCIAL_CONTRIBUTIONS}
                      data-gtm="gtm-newcar-navbar-social-contributions"
                    >
                      社会貢献活動
                    </Link>
                    <a
                      className={`${styles.navbar_icon_outer} ${styles.navbar_item_inner} navbar-item`}
                      href={NEWCAR_DEFINE.PATH.MEDIA}
                      data-gtm="gtm-newcar-navbar-column-media"
                      target="_blank"
                      rel="noreferrer"
                    >
                      メディア掲載情報
                    </a>
                  </div>
                </details>
                <Link
                  className={`${styles.navbar_icon} ${styles.navbar_item} navbar-item`}
                  to={NEWCAR_DEFINE.PATH.FAQ}
                  data-gtm="gtm-newcar-navbar-faq"
                >
                  よくあるご質問
                </Link>
                <Link
                  className={`${styles.navbar_icon} ${styles.navbar_item} navbar-item`}
                  to={NEWCAR_DEFINE.PATH.CONTACT}
                  data-gtm="gtm-newcar-navbar-contact"
                >
                  お問合せ
                </Link>
                <a
                  className={`${styles.navbar_icon} ${styles.navbar_item} navbar-item`}
                  href={NEWCAR_DEFINE.PATH.CORPORATE_FORM}
                  data-gtm="gtm-newcar-navbar-corporate-form"
                  target="_blank"
                  rel="noreferrer"
                >
                  法人・個人事業主のお客様
                </a>
                <span className="border" />
                <div className={`${styles.navbar_simu_button_sp_wrapper}`}>
                  <div className={`${styles.navbar_simu_button_sp}`}>
                    <Link className={`${styles.navbar_icon}`} to={NEWCAR_DEFINE.PATH.SIMULATION_ROUTING} data-gtm="gtm-newcar-navbar-simulation">
                      シミュレーション結果
                    </Link>
                  </div>
                  <div className={`${styles.navbar_simu_button_sp}`}>
                    <a className={`${styles.navbar_icon}`} href={NEWCAR_DEFINE.PATH.APP_REASSURANCE} data-gtm="gtm-newcar-navbar-newcar-app">
                      ご契約者様へ
                    </a>
                  </div>
                </div>
                <div className="has-text-centered">
                  <a href="https://carmo-kun.jp/pr/2025/newyear/" data-gtm="gtm-newcar-navbar-campaigns">
                    <picture>
                      <source srcSet={bnrLpAvif} type="image/avif" />
                      <img
                        src={bnrLp}
                        alt="最大3万円が当たる！お年玉キャンペーン2025"
                        width="343"
                        height="61"
                      />
                    </picture>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {isHomePage && <h1 className={`is-size-7 container ${styles.head_h1}`}>車がもらえるカーリース 残価精算なしで安心の車サブスク</h1>}
      {children}
    </header>
  );
};

export default Header;
